import m from 'mithril';

export function InputCompanyDataField({ attrs }) {
    const { company, property, subproperty, label, isEditable } = attrs;
    const save = (e) => {
        if (subproperty) {
            Object.keys(company.value).forEach((key) => {
                if (key === property) {
                    const newProperty = { [subproperty]: e.target.value };
                    attrs.save({ [property]: { ...company.value[key], ...newProperty } });
                }
            });
        } else {
            attrs.save({ [property]: e.target.value });
        }
    };
    return {
        view({ attrs }) {
            const { data, pattern, type, required, title } = attrs;
            const attributeClass = attrs.class !== undefined ? `${attrs.class} ` : '';
            return (
                <div class={isEditable.value ? `${attributeClass}label-and-input-edit` : `${attributeClass}label-and-input`}>
                    <div class="label-name">{label}</div>
                    {isEditable.value
                        ? <input class={`${attributeClass}form-input`} type={type || 'text'} pattern={pattern} value={data}
                            required={required} oninput={save} title={title} />
                        : <p class="datafield">{data} </p>}
                </div>
            );
        }
    };
}
