import 'babel-polyfill';
import m from 'mithril';
import { mainStory } from 'common/utils/storyboard';
import 'common/setup';
import _ from 'underscore';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';

import { App } from './app';

import 'spectre.css/dist/spectre.min.css';
import 'spectre.css/dist/spectre-icons.min.css';
import './main.scss';

const rootElement = document.getElementById('root');

// Auth.autologout.init();
Bus.Init({
    name: 'thea',
    version: '1.0.0',
    hub: {
        url: '/data'
    }
});

Bus.observe('ReceiveMessage', (channel, message) => mainStory.info(`{${channel}} ${message}`));
Bus.observe('Log', (channel, message) => console.log(`{${channel}} ${message}`));
Bus.observe('Message', (channel, message) => console.log(`{${channel}} ${message}`));

Bus.observe('LoginSuccess', m.redraw);
Bus.listen('Redraw', m.redraw);
Bus.observe('Reload', table => mainStory.info(`{reload} ${table}`));
Bus.observe('CurrentCompanyId', (value) => {
    Auth.updateCurrentCompany(value);
    m.redraw();
});

Bus.observe('SendEmailNotifications', threadId => Bus.secure('SendEmailNotifications', threadId));

mainStory.info(new Date().toLocaleString());

m.mount(rootElement, App);
