import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { ConfirmDeletion } from 'common/components/confirm-deletion-modal';

export function Toolbar() {
    const isPromptOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));
    return {
        view({ attrs }) {
            const { component, moveComponent, max, saveComponent, removeComponent } = attrs;
            if (!attrs.inEditMode) {
                return null;
            }
            return (
                <div class="component-toolbar">
                    <div class="form-group form-group-margin">
                        <label class="form-label toolbar-title">
                            {_`Handle Component`}:
                        </label>
                    </div>

                    <div class="form-group form-group-margin">
                        <label class="form-label tooltip" data-tooltip={_`The amount of width that the component\noccupies out of 12 columns`}
                            for="input-col-lg">
                            {_`Columns`}
                        </label>
                        <input class="form-input input-sm form-input-small" type="number" id="input-col-lg"
                            value={component.columnLg} min={1} max={12} onchange={e => saveComponent(e, component.id, 'columnLg')} />
                    </div>

                    <div class="divider-vert"></div>

                    <button class="edit-view-button tooltip" data-tooltip={_`Move Component Left`}
                        disabled={component.order === 1} onclick={e => moveComponent(e, component.order, component.order - 1)}>
                        <i class="icon icon-arrow-left"></i>
                    </button>
                    <input class="form-input input-sm form-input-small" value={component.order} disabled />
                    <button class="edit-view-button tooltip" data-tooltip={_`Move Component Right`}
                        disabled={component.order === max} onclick={e => moveComponent(e, component.order, component.order + 1)}>
                        <i class="icon icon-arrow-right"></i>
                    </button>

                    <div class="divider-vert"></div>

                    <button class="edit-view-button tooltip" data-tooltip={_`Delete Component`} onclick={() => isPromptOpen(true)}>
                        <i class="icon icon-delete"></i>
                    </button>
                    <ConfirmDeletion modalIsOpen={isPromptOpen} confirmEvent={() => removeComponent(component.id)} />
                </div>
            );
        }
    };
}
