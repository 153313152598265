import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Home } from './home';
import { Profile } from './profile';
import { MessageCenter, MessageIcon } from './messages/message-center';

import './main.scss';

export function Agora() {
    const listeners = [];
    const main = new Observable(Home)
        .filter((value, oldValue) => (oldValue !== value))
        .each(() => m.redraw());
    const attributes = new Observable(null);
    listeners.push(Bus.listen('ChangeView', (view, data) => {
        main(view.component || view);
        attributes(data);
    }));
    return {
        oncreate() {
            const items = [
                { name: _`Home`, clickFunction: () => Bus.emitLocal('ChangeView', Home, { name: _`Home` }) },
                { name: _`My Page`, clickFunction: () => Bus.emitLocal('ChangeView', Profile, { name: _`My Page` }) },
                {
                    name: 'Messages',
                    display: () => <MessageIcon />,
                    clickFunction: () => Bus.emitLocal('ChangeView', MessageCenter, { name: 'Messages' })
                },
            ];
            Bus.emitLocal('SetMenuData', items);
            Bus.emitLocal('ChangeView', Home);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <main.value class="column col-content m-0 p-0" {...attributes.value} />
            );
        }
    };
}
