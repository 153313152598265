import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Chart as ChartIcon } from 'common/components/assets/chart';
import { Table as TableIcon } from 'common/components/assets/table';
import { EditNote } from 'common/components/assets/edit-note';
import { Table, Chart, Editor } from './component-types';

export function AddComponentToolbar() {
    return {
        view({ attrs }) {
            const { createComponent } = attrs;
            return (
                <div class="add component-toolbar">

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip tooltip-right" data-tooltip={_`Add a table component`}
                            onclick={() => createComponent(Table)} data-test="add-table">
                            <TableIcon />
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add a chart component`}
                            onclick={() => createComponent(Chart)} data-test="add-chart">
                            <ChartIcon />
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an editor component`}
                            onclick={() => createComponent(Editor)} data-test="add-editor">
                            <EditNote />
                        </button>
                    </div>

                </div>
            );
        }
    };
}
