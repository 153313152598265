import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { MessageCenter, MessageIcon } from 'agora/messages/message-center';
import { Profile } from 'agora/profile';
import { Home } from 'agora/home';
import { ModuleOverview } from './module-overview';
import { CloneContent } from './clone-content';
import { ManageCompanies } from './manage/manage-companies';
import { ManageUsers } from './manage/manage-users';
import './main.scss';

export function Archon() {
    const listeners = [];
    const main = new Observable(Home)
        .filter((value, oldValue) => (oldValue !== value))
        .each(m.redraw);

    const attributes = new Observable(null);
    listeners.push(Bus.listen('ChangeView', (view, data) => {
        main(view.component || view);
        attributes(data);
    }));
    let languageChanged = false;
    listeners.push(Bus.listen('ChangeLanguage', () => {
        languageChanged = true;
    }));

    const createMenuData = () => {
        const items = [
            { name: _`Home`, clickFunction: () => Bus.emitLocal('ChangeView', Home, { name: _`Home` }) },
            { name: _`My Page`, clickFunction: () => Bus.emitLocal('ChangeView', Profile, { name: _`My Page` }) },
            { name: _`Manage Users`, clickFunction: () => Bus.emitLocal('ChangeView', ManageUsers, { name: _`Manage Users` }) },
            { name: _`Manage Companies`, clickFunction: () => Bus.emitLocal('ChangeView', ManageCompanies, { name: _`Manage Companies` }) },
            { name: _`Copy Modules and Views`, clickFunction: () => Bus.emitLocal('ChangeView', CloneContent, { name: _`Copy Modules and Views` }) }
        ];
        if (Auth.isAccountType('admin')) {
            items.push({
                name: _`Manage Modules`,
                clickFunction: () => Bus.emitLocal('ChangeView',
                ModuleOverview, { name: _`Manage Modules` })
            });
        }
        items.push({
            name: 'Messages',
            display: () => <MessageIcon />,
            clickFunction: () => Bus.emitLocal('ChangeView', MessageCenter, { name: 'Messages' })
        });
        Bus.emitLocal('SetMenuData', items);
    };

    return {
        oncreate() {
            createMenuData();
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (languageChanged) {
                createMenuData();
                languageChanged = false;
            }
            return (
                <main.value class="column col-content m-0 p-0" {...attributes.value} />
            );
        }
    };
}
