import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';

import './main.scss';

export function ViewRow({ attrs }) {
    return {
        view({ attrs }) {
            const { view, toggleModal, module } = attrs;
            return (
                <div key={`view-${view.id}`} class="columns view-item">
                    <div class="divider custom-divider column col-12"></div>
                    <div class="column col-1 move-div">
                        <button class="tooltip move-up"
                            data-tooltip={`${_`Move`} ${view.name} ${_`up`}`}
                            disabled={view.order === 1}
                            onclick={() => Bus.secure('MoveView', module.id, view.order, view.order - 1)}>
                            <i class="icon icon-arrow-up" />
                        </button>
                        {view.order}
                        <button class="tooltip move-down"
                            data-tooltip={`${_`Move`} ${view.name} ${_`down`}`}
                            disabled={view.order === module.views.length}
                            onclick={() => Bus.secure('MoveView', module.id, view.order, view.order + 1)}>
                            <i class="icon icon-arrow-down" />
                        </button>
                    </div>
                    <p class="column col-4">{view.name}</p>
                    <p class="column col-4">{view.active ? _`Active` : _`Inactive`}</p>
                    <div class="column col-2">
                        <button class="list-button transparent-button">
                            <i class="icon icon-cross" />
                        </button>
                        <button class="list-button tooltip"
                            data-tooltip={`${_`Edit`} ${view.name}`}
                            onclick={e => toggleModal(e, module, 'EditView', view)}>
                            <i class="icon icon-edit" />
                        </button>
                        <button class={'list-button tooltip'}
                            data-tooltip={`${view.active ? _`Deactivate` : _`Activate`} ${view.name}`}
                            onclick={e => toggleModal(e, module, 'StatusView', view)}>
                            <i class={`icon ${view.active ? 'icon-stop' : 'icon-refresh'}`} />
                        </button>
                    </div>
                </div>
            );
        }
    };
}
