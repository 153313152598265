import m from 'mithril';

export function EditDataField({ attrs }) {
    const { property, save } = attrs;
    const saveProperty = (e) => {
        e.preventDefault();
        save({ [property]: e.target.value });
    };

    return {
        view({ attrs }) {
            const { label, type, data, required, pattern } = attrs;
            return (
                <div class="label-and-input">
                    <p class="label-name"> {label} </p>
                    <input type={type || 'text'} class={`form-${type === 'checkbox' ? type : 'input'}`} defaultValue={data}
                        required={required} value={data || null} pattern={pattern} oninput={saveProperty} />
                </div>
            );
        }
    };
}

export function DisplayDataField() {
    return {
        view({ attrs }) {
            const { label, data } = attrs;
            return (
                <div class="label-and-input">
                    <p class="label-name"> {label} </p>
                    <p class="datafield">{data}</p>
                </div>
            );
        }
    };
}
