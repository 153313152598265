import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Countryflags } from 'common/utils/country-flags';
import { Bus } from 'common/utils/bus';
import { ChangeLanguage } from './change-language';
import { Logo } from '../logo';
import './main.scss';

export function Menu() {
    const listeners = [];
    const menuItems = [];
    listeners.push(Bus.listen('SetMenuData', (menuData) => {
        menuItems.length = 0;
        menuData.forEach(item => menuItems.push(item));
        menuItems.push({ name: _`Logout`, clickFunction: () => Bus.emit('Logout') });
        Bus.emitLocal('Redraw');
    }));
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { homeComponent } = attrs;
            const flag = Countryflags[_.locale];
            return (
                <header class="navbar columns">
                    <Logo homeComponent={homeComponent} />
                    <div class="col-10 m-2 move-left">
                        <div class="navbar-section hide-lg display-inline-block">
                            <div class="menu-items col-ml-auto">
                                <MenuData list={menuItems} class="text-right" flag={flag} />
                            </div>
                        </div>
                        <div class="show-lg mobile-menu display-inline-block">
                            <div class="dropdown dropdown-right">
                                <a href="#" class="btn btn-link dropdown-toggle" tabindex="0">
                                    <i class="icon icon-menu" />
                                </a>
                                <ul class="menu text-center">
                                    <MenuData list={menuItems} class='' flag={flag} />
                                </ul>
                            </div>
                        </div>
                        <div class="change-lang d-inline-block">
                            <ChangeLanguage flag={attrs.flag} />
                        </div>
                    </div>
                </header>
            );
        }
    };
}

function MenuData() {
    return {
        view({ attrs }) {
            return (
                <div class={attrs.class}>
                    {attrs.list.map((item, i) => (
                        <div key={item.name} class={attrs.class !== '' ? 'd-inline-block' : ''}>
                            <button
                                class={`btn btn-link menu-button ${item.class}`}
                                onclick={ev => item.clickFunction(ev)}>{display(item)}
                            </button>
                            { attrs.class === '' && i !== attrs.list.length - 1 ? <div class="divider"></div> : null}
                        </div>
                    ))}
                </div>
            );
        }
    };
}

function display(item) {
    if (item.display === undefined) {
        return item.name;
    }
    if (typeof item.display === 'function') {
        return item.display();
    }
    return item.display;
}
