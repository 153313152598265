import querystring from 'querystring';

// This needs to be specific for each project.
// This stores the auto login if you want to support it.
const AUTH_PODIUM = Buffer.from('AUTH_PROJECTX_STORE').toString('base64');

const schematic = {
  id: '',
  name: '',
  email: '',
  accountType: '',
  token: '',
  active: '',
  currentCompanyId: ''
};

const autologout = {
  init() {
    this.clearTick();
    this._auto = true;
    this._isTicking = false;
  },
  clearTick() {
    this._tick = null;
  },
  startTicking() {
    if (this._auto) {
      this._isTicking = true;
      this.tick();
    }
  },
  tick() {
    if (this._auto && this._isTicking) {
      this._tick = new Date();
    }
  }
};
Object.defineProperty(autologout, 'auto', { get() { return this._auto; } });
const autoLogoutProperties = {
  init: { value: () => autologout.init() },
  clear: { value: () => autologout.clearTick() },
  tick: { value: () => autologout.tick() },
  last_tick: { get: () => autologout._auto && autologout._isTicking && autologout._tick, enumerable: true },
};

const auth = { ...schematic };
const prototype = {
  signIn(data) {
    Object.assign(auth, verify(data));
    localStorage[AUTH_PODIUM] = Buffer.from(JSON.stringify(auth)).toString('base64');
    autologout.startTicking();
    return this;
  },
  signInFromQuery() {
    const data = querystring.parse(window.location.search.substr(1));
    this.signIn(data);
  },
  isAccountType(data) {
    return auth.accountType.toLowerCase() === data.toLowerCase();
  },
  includesAccountType(array) {
    return array.map(data => data.toLowerCase()).includes(auth.accountType.toLowerCase());
  },
  logout() {
    prototype.clear();
  },
  clearStorage() {
    localStorage.removeItem(AUTH_PODIUM);
  },
  clearData() {
    Object.assign(auth, schematic);
    return this;
  },
  clear() {
    prototype.clearStorage();
    prototype.clearData();
  },
  updateCurrentCompany(companyId) {
    auth.currentCompanyId = companyId;
  }
};

// Note: properties automatically created from schematic
const properties = Object.keys(schematic)
  .map(key => ({ [key]: { get: () => auth[key], enumerable: true } }))
  .concat({ isLoggedIn: { get: () => auth.email !== '', enumerable: true } })
  .concat({ inStorage: { get: () => localStorage[AUTH_PODIUM], enumerable: true } })
  .concat({ autologout: { get: () => Object.create(null, autoLogoutProperties), enumerable: true } })
  .reduce(Object.assign);
export const Auth = Object.create(prototype, properties);

function verify(data) {
  const keys = Object.keys(schematic);
  Object.keys(data)
    .filter(key => !keys.includes(key))
    .forEach(key => delete data[key]);
  return data;
}
