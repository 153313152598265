import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';
import { PageBuilder } from 'agora/page-builder';
import { SwitchCompanyModal } from './switch-company-modal';
import './main.scss';

export function SideMenu() {
    const listeners = [];

    const modalActive = new Observable(false)
        .filter(value => typeof value === 'boolean');

    const menu = new Observable(null)
        .each(() => Bus.emitLocal('Redraw'));

    listeners.push(Bus.observe('Menu', menu));
    listeners.push(Bus.observe('RefreshMenu', () => Bus.secure('GetMenu', Auth.currentCompanyId)));

    return {
        oncreate() {
            Bus.secure('GetMenuByAccount');
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            if (menu.value === null) {
                return null;
            }
            return [
                <div key="menu" class={`${attrs.class}`}>
                    <div key={`${menu.value.name}-${menu.value.id}`} class="side-menu-company">
                        <div onclick={e => modalActive(true)} class="side-menu-ctitle">
                            {menu.value.name}
                        </div>
                        <div>
                            {menu.value.modules.map((module, j) => (
                                <SideMenuModuleData
                                    key={`${menu.value.name}-${menu.value.id}-${j}`}
                                    class="side-menu-module"
                                    module={module} />
                            ))}
                        </div>
                    </div>
                </div>,
                <SwitchCompanyModal key="menu-modal" modalActive={modalActive} />
            ];
        }
    };
}

function SideMenuModuleData() {
    const isViewsVisible = new Observable(false)
        .each(() => Bus.emitLocal('Redraw'));

    const changeView = (e, view) => {
        e.stopPropagation();
        Bus.emitLocal('ChangeView', PageBuilder, { id: view.id, name: view.name });
    };
    return {
        view({ attrs }) {
            const { module } = attrs;
            return (
                <div key={`${module.name}-${module.id}`} class={attrs.class}>
                    <div class="side-menu-mtitle" onclick={() => isViewsVisible(!isViewsVisible.value)}>
                        <i class={`mb-1 mr-1 icon icon-arrow-${isViewsVisible.value ? 'down' : 'up'}`}></i>
                        <div onclick={e => changeView(e, module)}>{module.name}</div>
                    </div>
                    {isViewsVisible.value
                        ? module.views.map((view, i) => (
                            <li
                                key={view.name + i}
                                class="side-menu-vtitle"
                                onclick={e => changeView(e, view)}>
                                {view.name}
                            </li>
                        )) : null}
                </div>
            );
        }
    };
}
